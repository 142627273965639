import { Cookies } from '@saatva-bits/pattern-library.utils.cookies'

const getGoogleAnalyticsUserIdFromCookie = () => {
    const gaCookie = Cookies.get('_ga')

    if (!gaCookie) {
        return undefined
    }

    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split('.').slice(-2)
    if (userIdParts.length === 2) {
        return userIdParts.join('_')
    }
    return undefined
}

export { 
    getGoogleAnalyticsUserIdFromCookie
}
